var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-gray"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"uesr"},[_c('img',{attrs:{"src":_vm._f("Avatar")(_vm.userInfo.headimgUrl),"alt":""}}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.userInfo.nickname))])])])]),_c('div',{staticClass:"card orderInfo"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("我的订单")]),_c('div',{staticClass:"handle",on:{"click":function($event){return _vm.$router.push('/order/list')}}},[_vm._v(" 全部订单 "),_c('van-icon',{attrs:{"name":"arrow","color":"#999"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/order/list?type=1')}}},[(_vm.orderMap.waitingPayGoodsNum>0)?_c('van-badge',{attrs:{"content":_vm.orderMap.waitingPayGoodsNum}},[_c('img',{style:({
                    height: _vm.px2rem(19),
                    width: _vm.px2rem(19),
                }),attrs:{"src":require("./../../assets/images/mine/order_dfk@2x.png"),"alt":""}})]):_c('img',{style:({
                  height: _vm.px2rem(19),
                  width: _vm.px2rem(19),
              }),attrs:{"src":require("./../../assets/images/mine/order_dfk@2x.png"),"alt":""}}),_c('p',[_vm._v("待付款")])],1),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/order/list?type=2')}}},[(_vm.orderMap.waitingDeliverGoodsNum>0)?_c('van-badge',{attrs:{"content":_vm.orderMap.waitingDeliverGoodsNum}},[_c('img',{style:({
                    height: _vm.px2rem(19),
                    width: _vm.px2rem(22),
                }),attrs:{"src":require("./../../assets/images/mine/order_dsh@2x.png"),"alt":""}})]):_c('img',{style:({
                  height: _vm.px2rem(19),
                  width: _vm.px2rem(22),
              }),attrs:{"src":require("./../../assets/images/mine/order_dsh@2x.png"),"alt":""}}),_c('p',[_vm._v("待发货")])],1),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/order/list?type=3')}}},[(_vm.orderMap.waitingReceiveGoodsNum>0)?_c('van-badge',{attrs:{"content":_vm.orderMap.waitingReceiveGoodsNum}},[_c('img',{style:({
                    height: _vm.px2rem(17),
                    width: _vm.px2rem(19),
                }),attrs:{"src":require("./../../assets/images/mine/order_ywc@2x.png"),"alt":""}})]):_c('img',{style:({
                  height: _vm.px2rem(17),
                  width: _vm.px2rem(19),
              }),attrs:{"src":require("./../../assets/images/mine/order_ywc@2x.png"),"alt":""}}),_c('p',[_vm._v("待收货")])],1),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/afterSales/userAudit')}}},[(_vm.orderMap.afterSalesRequestNum>0)?_c('van-badge',{attrs:{"content":_vm.orderMap.afterSalesRequestNum}},[_c('img',{style:({
                    height: _vm.px2rem(19),
                    width: _vm.px2rem(20),
                }),attrs:{"src":require("./../../assets/images/mine/order_tkjl@2x.png"),"alt":""}})]):_c('img',{style:({
                  height: _vm.px2rem(19),
                  width: _vm.px2rem(20),
              }),attrs:{"src":require("./../../assets/images/mine/order_tkjl@2x.png"),"alt":""}}),_c('p',[_vm._v("退款/售后")])],1)])]),_c('div',{staticClass:"card commonly-used"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/mapPage')}}},[_c('img',{style:({
                            height: _vm.px2rem(30),
                            width: _vm.px2rem(30),
                        }),attrs:{"src":require("./../../assets/images/mine/shfw@2x.png"),"alt":""}}),_c('p',[_vm._v("联系我们")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/address/list')}}},[_c('img',{style:({
                            height: _vm.px2rem(30),
                            width: _vm.px2rem(30),
                        }),attrs:{"src":require("./../../assets/images/mine/dzgl@2x.png"),"alt":""}}),_c('p',[_vm._v("地址管理")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/student/newStudentList')}}},[_c('img',{style:({
                            height: _vm.px2rem(30),
                            width: _vm.px2rem(30),
                        }),attrs:{"src":require("./../../assets/images/mine/xsgl@2x.png"),"alt":""}}),_c('p',[_vm._v("学生管理")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/set/set')}}},[_c('img',{style:({
                            height: _vm.px2rem(30),
                            width: _vm.px2rem(30),
                        }),attrs:{"src":require("./../../assets/images/mine/set.ico.png"),"alt":""}}),_c('p',[_vm._v("设置")])])])]),(_vm.fansObj.bboss>0
    || _vm.fansObj.checkuser>0
    || _vm.fansObj.breturn>0
    || _vm.fansObj.bschool>0
    || _vm.fansObj.isReceiver>0)?_c('div',{staticClass:"card exclusive"},[_vm._m(1),_c('div',{staticClass:"row"},[(_vm.fansObj.bboss>0)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/management/login')}}},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/glzx@2x.png"),"alt":""}}),_c('p',[_vm._v("管理中心")])]):_vm._e(),(_vm.fansObj.bboss>0)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/schoolUniforms')}}},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/xftc@2x.png"),"alt":""}}),_c('p',[_vm._v("校服图册")])]):_vm._e(),(_vm.fansObj.bboss>0 ||_vm.fansObj.checkuser>0 || _vm.fansObj.bschool>0)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/audit')}}},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/sjss@2x.png"),"alt":""}}),_c('p',[_vm._v("上架审核")])]):_vm._e(),(_vm.fansObj.bboss>0)?_c('div',{staticClass:"item"},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/jxc@2x.png"),"alt":""}}),_c('p',[_vm._v("进销存")])]):_vm._e(),(_vm.fansObj.bboss>0)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/sales')}}},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/xstj@2x.png"),"alt":""}}),_c('p',[_vm._v("销售统计")])]):_vm._e(),(_vm.fansObj.bboss>0)?_c('div',{staticClass:"item"},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/hgz@2x.png"),"alt":""}}),_c('p',[_vm._v("合格证")])]):_vm._e(),(_vm.fansObj.bboss>0 || _vm.fansObj.breturn>0)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/refund/audit')}}},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/tksh@2x.png"),"alt":""}}),_c('p',[_vm._v("退款审核")])]):_vm._e(),(_vm.fansObj.bboss>0 || _vm.fansObj.checkuser>0 || _vm.fansObj.bschool>0)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/shelves/list')}}},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/spsj@2x.png"),"alt":""}}),_c('p',[_vm._v("商品上架")])]):_vm._e(),(_vm.fansObj.bboss>0 || _vm.fansObj.checkuser>0)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/school/list')}}},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/xxqh@2x.png"),"alt":""}}),_c('p',[_vm._v("学校切换")])]):_vm._e(),(_vm.fansObj.bboss>0)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push({path: '/enterSchoolCode'})}}},[_c('img',{style:({
                            height: _vm.px2rem(21),
                        }),attrs:{"src":require("./../../assets/images/mine/jffr@2x.png"),"alt":""}}),_c('p',[_vm._v("积分分润")])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("常用功能")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("专属功能")])])
}]

export { render, staticRenderFns }