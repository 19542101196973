<template>
  <div class="page-gray">
    <div class="top">
      <!-- Avatar是一个过滤器， 作用是当没有头像时， 使用默认头像来替代 -->
      <div class="uesr">
        <img :src="userInfo.headimgUrl | Avatar" alt="">
        <div class="right">
          <div class="name">{{ userInfo.nickname }}</div>
<!--          <div class="integralBox">-->
<!--            可用积分：1000-->

<!--          </div>-->
        </div>
      </div>

<!--      <div class="shuoming">
        <img src="./../../assets/images/mine/help@2x.png" alt="">
        <span>积分说明</span>
        <van-icon name="arrow-right" color="#333" :size="px2rem(10)"></van-icon>
      </div>-->

    </div>
    <div class="card orderInfo">
      <div class="title">
        <span>我的订单</span>

        <div class="handle" @click="$router.push('/order/list')">
          全部订单
          <van-icon name="arrow" color="#999"/>
        </div>
      </div>

      <div class="row">
        <div class="item" @click="$router.push('/order/list?type=1')">
          <van-badge :content="orderMap.waitingPayGoodsNum" v-if="orderMap.waitingPayGoodsNum>0">
            <img
                src="./../../assets/images/mine/order_dfk@2x.png"
                alt=""
                :style="{
                    height: px2rem(19),
                    width: px2rem(19),
                }"
            >
          </van-badge>
          <img
              v-else
              src="./../../assets/images/mine/order_dfk@2x.png"
              alt=""
              :style="{
                  height: px2rem(19),
                  width: px2rem(19),
              }"
          >
          <p>待付款</p>
        </div>
        <div class="item" @click="$router.push('/order/list?type=2')">
          <van-badge :content="orderMap.waitingDeliverGoodsNum" v-if="orderMap.waitingDeliverGoodsNum>0">
            <img
                src="./../../assets/images/mine/order_dsh@2x.png"
                alt=""
                :style="{
                    height: px2rem(19),
                    width: px2rem(22),
                }"
            >
          </van-badge>
          <img
              v-else
              src="./../../assets/images/mine/order_dsh@2x.png"
              alt=""
              :style="{
                  height: px2rem(19),
                  width: px2rem(22),
              }"
          >
          <p>待发货</p>
        </div>

        <div class="item" @click="$router.push('/order/list?type=3')">
          <van-badge :content="orderMap.waitingReceiveGoodsNum" v-if="orderMap.waitingReceiveGoodsNum>0">
            <img
                src="./../../assets/images/mine/order_ywc@2x.png"
                alt=""
                :style="{
                    height: px2rem(17),
                    width: px2rem(19),
                }"
            >
          </van-badge>
          <img
              v-else
              src="./../../assets/images/mine/order_ywc@2x.png"
              alt=""
              :style="{
                  height: px2rem(17),
                  width: px2rem(19),
              }"
          >
          <p>待收货</p>
        </div>

        <div class="item" @click="$router.push('/afterSales/userAudit')">
          <van-badge :content="orderMap.afterSalesRequestNum"  v-if="orderMap.afterSalesRequestNum>0">
            <img
                src="./../../assets/images/mine/order_tkjl@2x.png"
                alt=""
                :style="{
                    height: px2rem(19),
                    width: px2rem(20),
                }"
            >
          </van-badge>
          <img
              v-else
              src="./../../assets/images/mine/order_tkjl@2x.png"
              alt=""
              :style="{
                  height: px2rem(19),
                  width: px2rem(20),
              }"
          >
          <p>退款/售后</p>
        </div>
      </div>
      <!-- 物流信息， 可以隐藏 -->
<!--      <van-swipe vertical :show-indicators="false" :touchable="false" :autoplay="2000"
                 :style="{height: px2rem(40), marginTop: px2rem(15)}">
        <van-swipe-item :style="{height: px2rem(40)}">
          <div class="logistics" @click="$router.push('/order/logistics')">
            <img src="./../../assets/images/goods/goodsDetail/test/goods.png" alt="">
            <div class="right">
              <div class="state">派送中</div>
              <div class="info van-ellipsis">您的订单由【长沙望城分拣中心】送往【长沙香樟营您的订单由【长沙望城分拣中心】送往【长沙香樟营</div>
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item :style="{height: px2rem(40)}">
          <div class="logistics" @click="$router.push('/order/logistics')">
            <img src="./../../assets/images/goods/goodsDetail/test/goods.png" alt="">
            <div class="right">
              <div class="state">派送中</div>
              <div class="info van-ellipsis">您的订单由【长沙望城分拣中心】送往【长沙香樟营您的订单由【长沙望城分拣中心】送往【长沙香樟营</div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>-->
    </div>

    <div class="card commonly-used">
      <div class="title">
        <span>常用功能</span>

      </div>

      <div class="row">
        <div class="item" @click="$router.push('/mapPage')">
          <img
              src="./../../assets/images/mine/shfw@2x.png"
              alt=""
              :style="{
                            height: px2rem(30),
                            width: px2rem(30),
                        }"
          >
          <p>联系我们</p>
        </div>
<!--        <div class="item" @click="$router.push('/afterSales/list')">
          <img
              src="./../../assets/images/mine/shfw@2x.png"
              alt=""
              :style="{
                            height: px2rem(30),
                            width: px2rem(30),
                        }"
          >
          <p>售后服务</p>
        </div>-->
        <div class="item" @click="$router.push('/address/list')">
          <img
              src="./../../assets/images/mine/dzgl@2x.png"
              alt=""
              :style="{
                            height: px2rem(30),
                            width: px2rem(30),
                        }"
          >
          <p>地址管理</p>
        </div>
        <div class="item" @click="$router.push('/student/newStudentList')">
          <img
              src="./../../assets/images/mine/xsgl@2x.png"
              alt=""
              :style="{
                            height: px2rem(30),
                            width: px2rem(30),
                        }"
          >
          <p>学生管理</p>
        </div>
        <div class="item" @click="$router.push('/set/set')">
          <img
              src="./../../assets/images/mine/set.ico.png"
              alt=""
              :style="{
                            height: px2rem(30),
                            width: px2rem(30),
                        }"
          >
          <p>设置</p>
        </div>
<!--        <div class="item" @click="$router.push('/electronicNameTicker/list')">
          <img
              src="./../../assets/images/mine/dzxmt@2x.png"
              alt=""
              :style="{
                            height: px2rem(30),
                            width: px2rem(30),
                        }"
          >
          <p>电子姓名贴</p>
        </div>-->
      </div>
    </div>

    <div class="card exclusive" v-if="fansObj.bboss>0
    || fansObj.checkuser>0
    || fansObj.breturn>0
    || fansObj.bschool>0
    || fansObj.isReceiver>0">
      <div class="title">
        <span>专属功能</span>

      </div>

      <div class="row">
        <div class="item"  v-if="fansObj.bboss>0" @click="$router.push('/management/login')">
          <img
              src="./../../assets/images/mine/glzx@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>管理中心</p>
        </div>
        <div class="item" v-if="fansObj.bboss>0" @click="$router.push('/schoolUniforms')">
          <img
              src="./../../assets/images/mine/xftc@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>校服图册</p>
        </div>
        <div class="item"  v-if="fansObj.bboss>0 ||fansObj.checkuser>0 || fansObj.bschool>0" @click="$router.push('/audit')">
          <img
              src="./../../assets/images/mine/sjss@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>上架审核</p>
        </div>
        <div class="item"  v-if="fansObj.bboss>0">
          <img
              src="./../../assets/images/mine/jxc@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>进销存</p>
        </div>
        <div class="item" @click="$router.push('/sales')"  v-if="fansObj.bboss>0">
          <img
              src="./../../assets/images/mine/xstj@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>销售统计</p>
        </div>

        <div class="item"  v-if="fansObj.bboss>0">
          <img
              src="./../../assets/images/mine/hgz@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>合格证</p>
        </div>

        <div class="item" @click="$router.push('/refund/audit')"  v-if="fansObj.bboss>0 || fansObj.breturn>0">
          <img
              src="./../../assets/images/mine/tksh@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>退款审核</p>
        </div>

        <div class="item" @click="$router.push('/shelves/list')"  v-if="fansObj.bboss>0 || fansObj.checkuser>0 || fansObj.bschool>0">
          <img
              src="./../../assets/images/mine/spsj@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>商品上架</p>
        </div>

        <div class="item" @click="$router.push('/school/list')"  v-if="fansObj.bboss>0 || fansObj.checkuser>0">
          <img
              src="./../../assets/images/mine/xxqh@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>学校切换</p>
        </div>
        <!--$router.push('/integral/index')-->
        <div class="item" @click="$router.push({path: '/enterSchoolCode'})"  v-if="fansObj.bboss>0">
          <img
              src="./../../assets/images/mine/jffr@2x.png"
              alt=""
              :style="{
                            height: px2rem(21),
                        }"
          >
          <p>积分分润</p>
        </div>
<!--        <div class="item" @click="$router.push({path: '/store/index/123'})">-->
<!--          <img-->
<!--              src="./../../assets/images/mine/jffr@2x.png"-->
<!--              alt=""-->
<!--              :style="{-->
<!--                            height: px2rem(21),-->
<!--                        }"-->
<!--          >-->
<!--          <p>门店</p>-->
<!--        </div>-->
      </div>
    </div>


<!--    <integral-mall/>-->
  </div>
</template>

<script>
// import integralMall from '../../components/integralMall.vue'
import {mapState} from 'vuex'
import Vue from "vue";

export default {
  // components: {integralMall},
  name: "Mine",
  data() {
    return {
      fansObj:{},
      orderMap:{},
    }
  },
  methods:{
    init(){
      this.$http.get(this.baseMpUrl + `/mp-wx/obtainUserPermissions`).then(res => {
        this.fansObj = res.data;
      })
      //查询各个订单数量
      this.$http.get(this.baseMallUrl + `/h5_mall_order/myPageInfoNum/${Vue.prototype.deptId}`).then(res => {
        this.orderMap = res.data;
      })
    }
  },
  activated() {
    this.init();
  },
  computed: {
    ...mapState(['userInfo'])
  }
}

</script>
<style lang="less" scoped>
.page-gray {
  padding-bottom: 1px;
  -webkit-overflow-scrolling: touch;


}

.top {
  background-image: url('./../../assets/images/mine/top_background.png');
  height: 186px;
  width: 100%;
  background-size: contain;

  padding: 15px 18px;

  position: relative;

  .shuoming {
    height: 24px;
    background: rgba(255, 208, 200, .5);
    border-radius: 12px 0px 0px 12px;
    display: inline-flex;
    padding: 0 10px 0 2px;
    align-items: center;
    position: absolute;
    top: 22px;
    right: 0;

    img {
      height: 18px;
      width: 18px;
    }

    span {
      font-size: 11px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin: 0 7px;
    }
  }

  .uesr {
    display: flex;
    align-items: center;

    > img {
      width: 55px;
      min-width: 55px;
      height: 55px;
      border: 2px solid #fff;
      border-radius: 50%;
    }

    .right {
      margin-left: 20px;

      .name {
        line-height: 15px;
        font-size: 18px;
        color: #212121;
        margin-bottom: 6px;
      }

      .integralBox {

        border-radius: 9px;
        display: flex;
        align-items: center;
        padding: 4px 2px 2px 0;
        line-height: 14px;
        font-size: 12px;

        color: #666;

        > img {
          height: 14px;
          margin-left: 5px;
        }
      }
    }
  }
}

.card {
  margin: 10px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 15px 19px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 14px;

    > span {
      height: 14px;
      font-size: 14px;
      font-weight: 500;
      color: #212121;
    }

    .handle {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }

}

.orderInfo {
  margin-top: -100px;
  position: relative;
  z-index: 1;

  .row {
    display: flex;
    padding-top: 23px;

    .item {
      margin-right: 49px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      p {
        line-height: 12px;
        font-size: 12px;
        color: #666666;
        margin-top: 8px;
        text-align: center;
      }

    }
  }

  .logistics {

    background: #F2F2F2;
    border-radius: 5px;
    display: flex;
    align-items: center;

    img {
      height: 38px;
      width: 46px;
      min-width: 46px;
    }

    .right {
      flex: 1;
      width: 0;
      padding: 7px 8px 7px 10px;

      .state {
        font-size: 10px;
        color: #212121;
        font-weight: 500;
        line-height: 1;
      }

      .info {
        font-size: 10px;
        line-height: 1;
        color: #999999;
        margin-top: 4px;
      }
    }

  }
}

.commonly-used {


  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 18px;

    .item {
      text-align: center;

      p {
        line-height: 12px;
        font-size: 12px;
        color: #666666;
        margin-top: 6px;
      }
    }
  }
}

.exclusive {
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .item {
      width: 20%;
      margin-top: 21px;
      text-align: center;

      p {
        line-height: 12px;
        font-size: 12px;
        color: #666666;
        margin-top: 6px;
      }
    }
  }
}

::v-deep .van-badge--fixed {
  //top: 1px;
  //right: 6px;
  border: 1px solid #fff;
}
</style>
